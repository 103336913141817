<script>
import { storeForms } from '@app/mixins/forms';
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import StoreNavSettings from '@app/components/StoreNavSettings.vue';
import StoreLoginSettings from '@app/components/StoreLoginSettings.vue';
import StoreAlertMessagesSettings from '@app/components/StoreAlertMessagesSettings.vue';
import StoreHomeSettings from '@app/components/StoreHomeSettings.vue';
import StoreHomeVideoSettings from '@app/components/StoreHomeVideoSettings.vue';
import StoreResearchSettings from '@app/components/StoreResearchSettings.vue';
import Store2FASettings from '@app/components/Store2FASettings.vue';

export default {
  mixins: [storeForms],
  components: {
    TiptapEditor,
    StoreNavSettings,
    StoreLoginSettings,
    StoreAlertMessagesSettings,
    StoreHomeSettings,
    StoreHomeVideoSettings,
    StoreResearchSettings,
    Store2FASettings,
  },
  head: {
    title: 'Pages',
  },
  data() {
    return {
      store: {
        description: '',
      },
    };
  },
};
</script>

<template>
  <div class="ppage">
    <AppToggleSection>
      <template #title>
        Général
      </template>
      <template #default>
        <StoreNavSettings />
        <StoreAlertMessagesSettings />
      </template>
    </AppToggleSection>

    <AppToggleSection>
      <template #title>
        Page de connexion
      </template>

      <template #default>
        <StoreLoginSettings />

        <Store2FASettings />
      </template>
    </AppToggleSection>

    <AppToggleSection>
      <template #title>
        Page d'accueil
      </template>

      <template #default>
        <StoreHomeSettings />
        <StoreResearchSettings />
        <StoreHomeVideoSettings />
        <AppFeatureWrapper>
          <template #title>
            Votre page d'accueil
          </template>
          <template #content>
            <p>
              Ajoutez une description à votre page d'accueil pour décrire vos formations.
            </p>
            <p class="has-text-weight-bold">
              Cette description apparaîtra sur votre page d'accueil
              accessible en
              <a :href="$store.getters['auth/url']" target="_blank">cliquant ici</a>.
            </p>
          </template>
          <template #form>
            <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
              <b-field label="Description affichée sur votre page">
                <TiptapEditor :value="store.description" @input="store.description = $event" />
              </b-field>
              <b-field class="has-text-right">
                <b-button type="is-primary" native-type="submit" :loading="isLoading">
                  Mettre à jour
                </b-button>
              </b-field>
            </form>
          </template>
        </AppFeatureWrapper>
      </template>
    </AppToggleSection>
  </div>
</template>
